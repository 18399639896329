.notifications {
  color: #000000;
  .notifications-switch {
    background-color: rgb(238, 238, 238);
    border-radius: 5px;
    border: 0.2px solid lightgray;
  }
  .notifications-option {
    padding: 5px;
    border-radius: 5px;
    padding: 3px 5px 5px 5px;
    background-color: rgb(238, 238, 238);
    cursor: pointer;
    &:hover {
      background-color: rgb(46, 46, 46);
    }
  }
  .notifications-option-active {
    background-color: rgb(46, 46, 46);
  }
  .notifications-input {
    padding: 5px !important;
  }
  .notifications-select {
    padding: 8px !important;
  }
  .page-link {
    color: black;
    &:hover {
      color: var(--xiclos-red-dark);
    }
  }
  .page-item.active {
    .page-link {
      z-index: 1;
      color: #fff;
      background-color: #eb564a;
      border-color: black;
    }
  }
}
