.panel-control-users {
  color: #000000;
  .paper-content {
    padding: 40px;
  }
  .paper-container {
    padding: 10px;
    background-image: linear-gradient(
      to right,
      rgb(26, 26, 26) 0%,
      rgb(52, 52, 51) 49%,
      rgb(26, 26, 26) 100%
    );
    padding-bottom: 50px;
  }
  .page-item.active {
    .page-link {
      z-index: 1;
      color: #fff;
      background-color: #eb564a;
      border-color: black;
    }
  }
  .page-link {
    color: black;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  #sign-in-back {
    position: absolute;
    top: 0;
    height: 40px;
    width: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    margin: 1em;
    font-size: 2em;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    cursor: pointer;
    transition-duration: 500ms;
  }
  .m-profile {
    .sign-user_card {
      padding: 30px;
      border-radius: 8px;
      background: #ffffff;
    }
    .form-control {
      color: #000000;
      height: 5em;
      margin: 24px 0 22px;
      padding: 24px 129px 24px 32px;
      border-radius: 8px;
      border: solid 1px #eae9f2;
      background-color: #ffffff;
    }
  }
  .manage-p {
    height: 100%;
    background-image: linear-gradient(
      to right,
      #1a1a1a 0%,
      #343433 49%,
      #1a1a1a 100%
    );
    width: 100%;
  }
  .text-footer {
    font-family: "Abel";
    color: white;
  }
  .card-arrow {
    color: white;
    font-size: 2.5em;
    cursor: pointer;
  }
  .arrow-title {
    color: white;
    font-family: Abel;
    font-size: 1.5em;
  }
  .btn-cancel {
    background-color: white;
    color: red;
  }
  .sign-in-page {
    .form-control {
      color: #000000;
      height: 5em;
      margin: 24px 0 22px;
      padding: 24px 129px 24px 32px;
      border-radius: 8px;
      border: solid 1px #eae9f2;
      background-color: #ffffff;
    }
  }
  .form-control {
    &:focus {
      box-shadow: none;
    }
  }
  hr {
    margin-bottom: 1rem;
    border: 0;
  }
  .option-hover {
    transition: color 0.3s ease;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #f1f1f1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: 50%;
      transform-origin: 50%;
      transition: transform 0.3s ease-out;
      -webkit-transition: transform 0.3s ease-out;
      border-radius: 5px;
    }
    &:hover {
      background-image: linear-gradient(
        to right,
        #eb564a 20%,
        #343433 49%,
        #1a1a1a 100%
      );
      color: var(--iq-white);
      &:before {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .list-group-item {
    border-radius: 0.25em;
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  .dropbtn {
    background-color: #4caf50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  .dropdown {
    position: relative;
    cursor: pointer;
    &:hover {
      .dropdown-content {
        display: block;
        z-index: 1;
      }
      background-color: #7a887a;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      &:hover {
        background-image: linear-gradient(
          to right,
          #eb564a 20%,
          #343433 73%,
          #1a1a1a 100%
        );
        color: var(--iq-white);
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        border-radius: 10px;
      }
    }
  }
  #user-table {
    text-align: center;
  }
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #000000;
    animation: spin 1s ease infinite;
  }
  .check-icon {
    font-weight: bolder;
    background-color: #6fcf97;
    color: white;
    font-size: 2em;
    border-radius: 32px;
    width: 2em;
    height: 2em;
    margin-bottom: 0.5em;
    padding: 8px 16px 14px;
  }
  .cancel-icon {
    font-weight: bolder;
    background-color: #cf6f6f;
    color: white;
    font-size: 2em;
    border-radius: 32px;
    width: 2em;
    height: 2em;
    margin-bottom: 0.5em;
    padding: 8px 16px 14px;
  }
  body {
    color: #000000;
  }

  .m-btn-export {
    margin: 10px;
  }
  @media screen and (min-width: 992px) and (max-width: 1321px) {
    .padding-top {
      padding-top: 3em;
      padding-top: 3em;
    }
  }
  @media screen and (max-width: 767px) {
    .manage-p {
      height: 100%;
      background-image: linear-gradient(
        to right,
        #1a1a1a 0%,
        #343433 49%,
        #1a1a1a 100%
      );
    }
  }
  @media screen and (max-width: 480px) {
    .sign-in-page {
      .form-control {
        color: #000000;
        height: 5em;
        margin: 24px 0 22px;
        padding: 24px 45px 24px 32px;
        border-radius: 8px;
        border: solid 1px #eae9f2;
        background-color: #ffffff;
      }
    }
    .m-profile {
      .form-control {
        color: #000000;
        height: 5em;
        margin: 24px 0 22px;
        padding: 24px 45px 24px 32px;
        border-radius: 8px;
        border: solid 1px #eae9f2;
        background-color: #ffffff;
      }
    }
    .btn {
      padding: 8px 4px;
      width: 12em;
      font-size: 0.8em;
    }
  }
  @media screen and (max-width: 375px) {
    .manage-p {
      height: 100%;
      background-image: linear-gradient(
        to right,
        #1a1a1a 0%,
        #343433 49%,
        #1a1a1a 100%
      );
    }
    .m-profile {
      .sign-user_card {
        padding: 15px;
        width: 22em;
      }
    }
  }
  @media screen and (max-width: 321px) {
    .manage-p {
      height: 100%;
      background-image: linear-gradient(
        to right,
        #1a1a1a 0%,
        #343433 49%,
        #1a1a1a 100%
      );
    }
    .m-profile {
      .sign-user_card {
        padding: 15px;
        width: 19em;
      }
    }
  }
}
