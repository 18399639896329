.sync-device {
  .paper-card {
    padding: 40px;
  }
  .main-content {
    padding: 20px;
    padding-bottom: 50px;
    background-image: linear-gradient(
      to right,
      rgb(26, 26, 26) 0%,
      rgb(52, 52, 51) 49%,
      rgb(26, 26, 26) 100%
    );
  }
  .list-devices {
    height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }
}
