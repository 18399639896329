.search-header {
  .search-bar-mobile {
    z-index: 1;
    min-width: 22em;
    left: -400%;
  }
  .search-bar {
    min-width: 25em;
  }
  .search-button-mobile {
    padding: 0;
    .search-icon {
      color: #d1d0cf;
    }
  }
}
