/*------------- Variable ------------*/

:root {
  --iq-primary: #eb564a;
  --iq-primary-hover: #bf000a;
  --iq-light-primary: #d7d3f8;
  --iq-secondary: #6c757d;
  --iq-white: #ffffff;
  --iq-black: #000000;
  --iq-title-text: #23203c;
  --iq-body-text: #d1d0cf;
  --iq-body-bg: #141414;
  --iq-bg1: #191919;
  --iq-body-border: #393750;
  --iq-gold: #e6c75f;
  --iq-black: #000000;
  --xiclos-red: #eb564a;
  --xiclos-red-dark: #732323;
  --xiclos-title: #343433;
  --xiclos-text: #5e5e5e;
  --xiclos-border-form: #eae9f2;
  --xiclos-bg: #2d2d2d;
  --xiclos-bg-gradient-dark: linear-gradient(
    to right,
    #1a1a1a 0%,
    #343433 49%,
    #1a1a1a 100%
  );
  --xiclos-font-helv-bold: "Helvetica Neue Bold";
  --xiclos-font-helv-medium: "Helvetica Neue Medium";
  --xiclos-font-helv-normal: "Helvetica Neue Regular";
  --xiclos-font-abel: "Abel";
  --xiclos-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
}
